<template>
  <main>
    <div style="padding-left: 20px; padding-right: 20px">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="Настройки"
      />
      <div style="max-width: 25rem">
        <h3>Смена пароля</h3>
        <a-input-password
          placeholder="Старый пароль"
          v-model="password.old"
        ></a-input-password>
        <a-input-password
          placeholder="Новый пароль"
          v-model="password.new"
        ></a-input-password>
        <a-button type="primary" @click="setPassword"> Принять </a-button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      password: {
        old: null,
        new: null,
      },
    };
  },
  methods: {
    ...mapActions(["resetLoginStates"]),
    logout() {
      this.resetLoginStates();
      this.$router.push("/login");
    },
    setPassword() {
      axios
        .post("/cp/users/self/password/", this.password)
        .then((res) => {
          console.log(res);
          this.password = {
            old: null,
            new: null,
          };
          alert("Пароль обновлен");
          this.logout();
        })
        .catch((e) => {
          alert(e.description);
        });
    },
  },
};
</script>
